<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Document'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/hrdocument`" title="Document" :columns="columns"
                routerpath="/master/hr/adddocument" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Holiday', route: '/master/hr/holiday', icon: 'CalendarIcon' },
      //   { name: 'HolidayName', route: '/master/hr/holidayname', icon: 'BarChart2Icon' },
      //   { name: 'Region', route: '/master/hr/regionmaster', icon: 'CrosshairIcon' },
      //   { name: 'ApplyPost', route: '/master/hr/applypost', icon: 'HardDriveIcon' },
      //   { name: 'Asset', route: '/master/hr/assetmaster', icon: 'CompassIcon' },
      //   { name: 'Cast', route: '/master/hr/cast', icon: 'CastIcon' },
      //   { name: 'Document', route: '/master/hr/document', icon: 'FileIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      viewFields: [
        {
          label: 'Document Name',
          field: 'name',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Document',
          field: 'document',
          type: 'attachment',
          class: 'col-md-4'
        }
      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Document Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Document Name",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Document" : "Add Document"
          }`,
        submitRouterPath: "/master/hr/document",
        usersDropdown: true,
        getEditValue: `${baseApi}/gethrdocumentById`,
        branchDropdown: true,
        designationDropdown: true,
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/hrdocument`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Document Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Document Name",
            class: "col-md-12",
            required: true,
          },
          {
            label: "Document",
            name: "document",
            value: "",
            type: "fileinput",
            placeholder: "Select Document",
            class: "col-md-12",
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
          },
        ],
      },

      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Document") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
